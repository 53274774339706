require('./bootstrap');

jQuery(document).ready(function(){
	if(jQuery('.hero').length > 0 && jQuery('.frontpage--content__content').length > 0){
		jQuery('.frontpage--content__content').height(jQuery('.hero').height() - 30);
		jQuery('.container--breadcrumbs').hide();
	}

	jQuery('a.print').on('click', function(ev){
		ev.preventDefault();
		window.print();
	});

	jQuery('.navigation--confidential li > a[href="#0"]').on('click', function(ev){
		ev.preventDefault();
		var $this = jQuery(this),
			ul = $this.find('+ ul');

		ul.slideToggle(200);
		
		setTimeout(function(){
			ul.toggleClass('hidden');
		}, 210);
	});

	if( jQuery('.hero--image').length > 1 ){
		var runHeroSlider = setInterval(function(){
			var active = jQuery('.hero--image.active'),
				next = active.next('.hero--image');

				if(next.length == 0){
					next = jQuery('.hero--image:first-child');
				}

				active.removeClass('active');
				next.addClass('active');
		}, 8000);
	}
});